.viewAgenda {
    width: 100%;
    max-width: 1000px;
    margin: 0px auto;
    height: calc(100% - 50px);
    padding: 0px;
    overflow-y: scroll;

    .left {
        width: 50%;
        max-width: 350px;
        padding: 0px 30px 0px 30px;

        .date {
            .day {
                font-size: 6em;
                line-height: calc(1em);
                margin-bottom: 0px;
            }

            .date {
                margin-top: 0px;
            }
        }

        .miniCalendar {
            margin-top: 100px;

            .name {
                color: $danger;
                font-weight: 400;
                letter-spacing: 1px;
                text-transform: uppercase;
                cursor: pointer;
            }

            .week {
                .day {
                    width: calc(100% / 7);
                }

                .realDate {
                    cursor: pointer;
                }
            }
        }
    }

    .right {
        width: 100%;
        padding: 10px;
        font-size: 0.8em;

        .ruler {
            margin-right: 10px;
            height: calc(24 * 40px);

            p {
                height: 40px;
                padding: 0px;
                margin: 0px;
                color: $gray-600;
                text-align: right;
            }
        }

        .arrangement,
        .reminder,
        .task {
            margin: 0px;
            margin-bottom: 10px;

            p {
                height: 40px !important;
                padding: 0px;
                margin-bottom: 0px;
                font-size: 1.2em;
            }
        }
    }
}
