.viewMonth {
    width: 100%;
    height: 100%;
    margin-left: 2.5px;

    .week {
        height: calc((100% - 30px - 20px) / 6);
    }

    .daysOfWeek {
        height: 20px;

        p {
            margin-top: 0px !important;
        }

        .day {
            border-top: 0;
            color: #a5a5a5;
        }
    }

    .day {
        width: calc(100% / 7 - 5px);
        margin: 2px;
        border-top: 1px solid #a5a5a5;
        background-color: $white;
        overflow-y: scroll;

        p {
            margin: 0px;
            margin-top: 2px;
            margin-bottom: 0px;
        }
    }

    .day-5,
    .day-6 {
        background-color: $gray-100;
    }

    div.today {
        border-top: 1px solid $danger;
    }

    p.today {
        color: $danger;
        font-weight: 700;
    }

    .arrangement,
    .reminder,
    .task {
        font-size: 80%;
    }
}
