.formCard {
    margin: 100px auto 100px auto;
    padding: 15px;
    width: 100%;
    max-width: 330px;
}

Form {
    .top {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .middle {
        margin-bottom: -1px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .bottom {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    img {
        width: 72px;
        height: 72px;
    }
}

Form.register {
    max-width: 380px;
}
