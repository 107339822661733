$primary: #4f7cac;
$success: #51be70;
$warning: #ecd159;
$danger: #c7634d;
$info: #b9e6ff;

.btn-primary,
.btn-outline-primary:hover,
.btn-danger,
.btn-danger-primary:hover {
    color: white !important;
}

@import "~bootstrap/scss/bootstrap.scss";

.calendar-color-1 {
    color: $red;
}

.calendar-color-2 {
    color: $orange;
}

.calendar-color-3 {
    color: $yellow-600;
}

.calendar-color-4 {
    color: $green;
}

.calendar-color-5 {
    color: $blue;
}

.calendar-color-6 {
    color: $purple;
}

.calendar-outline-color-1 {
    border: 1px solid $red !important;
    border-radius: 3px;
}

.calendar-outline-color-2 {
    border: 1px solid $orange !important;
    border-radius: 3px;
}

.calendar-outline-color-3 {
    border: 1px solid $yellow-600 !important;
    border-radius: 3px;
}

.calendar-outline-color-4 {
    border: 1px solid $green !important;
    border-radius: 3px;
}

.calendar-outline-color-5 {
    border: 1px solid $blue !important;
    border-radius: 3px;
}

.calendar-outline-color-6 {
    border: 1px solid $purple !important;
    border-radius: 3px;
}

.calendar-background-color-1 {
    background-color: $red-100;
}

.calendar-background-color-2 {
    background-color: $orange-100;
}

.calendar-background-color-3 {
    background-color: $yellow-100;
}

.calendar-background-color-4 {
    background-color: $green-100;
}

.calendar-background-color-5 {
    background-color: $blue-100;
}

.calendar-background-color-6 {
    background-color: $purple-100;
}
