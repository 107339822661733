.viewYear {
    width: 100%;
    height: 100%;

    .yearRow {
        height: calc((100% - 50px) / 3);
    }

    .month {
        background-color: $white;
        width: calc(100% / 4);
        padding: 10px;
        overflow-y: scroll;

        .name {
            color: $danger;
            font-weight: 400;
            letter-spacing: 1px;
            text-transform: uppercase;
            cursor: pointer;
        }

        .week {
            .day {
                width: calc(100% / 7);
                line-height: calc(1em - 5px);
            }
        }
    }
}
